<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">WIFI VOUCHER</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-row>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="name"
                    label="Voucher"
                    dense
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                    @keyup.enter="save_caller"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12" v-if="id===0">
              <v-btn color="primary" class="me-3 mt-4" @click="save_caller" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
            <v-col cols="12" v-else>
              <v-btn color="primary" class="me-3 mt-4" @click="update_info" v-if="!saving"> Update
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="mt-4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Voucher</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in initialize_datas" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.voucher_no }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
import {
  mdiAlertOutline,
  mdiCloudUploadOutline,
  mdiAccountSearchOutline,
  mdiToggleSwitch,
  mdiToggleSwitchOff,
  mdiPencilBoxOutline
} from '@mdi/js'
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

const initialState = () => {
  return {
    cropped: require('@/assets/images/avatars/2.png'),

    branch_info: '',
    branch_items: [],

    saving: false,
    alert: false,
    alert_message: '',

    b_id: 0,
    id: 0,
    id_no: '',
    email: '',
    contact_no: '',
    name: '',
    position: '',

    initialize_datas: [],
    is_updating: false,
  }
}

export default {
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      show: false,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiAccountSearchOutline,
        mdiPencilBoxOutline,
        mdiToggleSwitch,
        mdiToggleSwitchOff,
      },
    }
  },
  mounted() {
    this.initialize_data()
  },
  data() {
    return initialState()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id','branch_id_selected']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('purchase_order_slip', ['register_wifi_voucher', 'get_all_available_wifi_voucher', ]),
    ...mapActions('branch_information', ['get_branches_active']),
    initialize_data() {
      this.get_all_available_wifi_voucher({
        branch_id:this.branch_id===1?this.branch_id_selected:this.branch_id
      })
        .then(response => {
          this.initialize_datas = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    reset() {
      Object.assign(this.$data, initialState())
      this.initialize_data()
    },
    save_caller() {
      this.saving = true
      this.alert = false
      if (this.$refs.form.validate()) {
        const data = new FormData()
        data.append('branch_id', this.branch_id===1?this.branch_id_selected:this.branch_id)
        data.append('voucher_no', this.name)
        this.register_wifi_voucher(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            } else {
              this.reset()
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
          })
          .catch(error => {
            console.log(error)
            this.alert = true
            this.alert_message = error.response.data.message
            this.saving = false
          })
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving = false
      }
    },
  },
}
</script>
